import './slideout'

import { detectAnyAdblocker } from 'just-detect-adblock'

detectAnyAdblocker().then((detected) => {
    if(detected){
        document.body.classList.add('adblock-detected')
    }
});

function showDropDownBackGround(event) {
    var menuItemDropDownUnderlay = document.querySelector("#dropdown_underlay");
    if (menuItemDropDownUnderlay.classList.contains('hide')) {
        menuItemDropDownUnderlay.classList.remove("hide");
    } else {
        menuItemDropDownUnderlay.classList.add("hide");
    }
}

document.querySelectorAll('.toggle-data-target').forEach(function(el) {
    el.addEventListener('click', toggleDataTarget, false);
});

function toggleDataTarget(el) {
    const targetElement = document.querySelector('.' + el.target.getAttribute('data-target'));
    if (!targetElement.classList.contains('show')) {
        targetElement.classList.add('show');
    } else {
        targetElement.classList.remove('show');
    }
}

var vimeo = document.querySelectorAll('.video-player');
for (var i = 0; i < vimeo.length; i++) {
    vimeo[i].addEventListener('click', function() {
        this.classList.add('active');
        var iframe = document.createElement('iframe');

        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('height', '100%');
        iframe.setAttribute('width', '100%');
        iframe.setAttribute('allowfullscreen', '');
        iframe.setAttribute('src', this.dataset.embed + '?rel=0&showinfo=0&autoplay=1');

        this.innerHTML = '';
        this.appendChild(iframe);
    });
}

$(document).ready(function() {
    // Select all <a> elements within 'nav-tabs' class
    $('ul.nav-tabs a').each(function() {
        // Check if the link is external
        if(this.hostname !== window.location.hostname) {
            // Set target to _blank
            $(this).attr('target', '_blank');
            // Optional: Add rel="noopener noreferrer" for security and performance reasons
            $(this).attr('rel', 'noopener noreferrer');
        }
    });
});
